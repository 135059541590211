import { css } from "@emotion/css";
import { useEffect, useRef } from "react";
import Balancer from "react-wrap-balancer";
import Flex from "../Flex";
import { ResetUaStyles } from "../ResetUaStyles";
import Backdrop from "./Backdrop";
import Dialog from "./Dialog";
import ModalWindowButton from "./ModalWindowButton";
import ModalWindowProps from "./ModalWindowProps";

function useDisableBodyScroll(disable: boolean) {
  useEffect(() => {
    if (disable && document.documentElement.style.overflow === "") {
      document.documentElement.style.overflow = "hidden";
      return () => {
        document.documentElement.style.overflow = "";
      };
    }
  }, [disable]);
}

const ModalWindow: React.FC<ModalWindowProps> = (props) => {
  let inputRef = useRef<HTMLInputElement | null>(null);

  useDisableBodyScroll(props.type != null);

  if (props.type == null) {
    return null;
  }

  const handleCancel = () => {
    return props.resolve(undefined);
  };

  const handleOk = () => {
    if (props.type === "prompt") {
      props.resolve(inputRef.current?.value);
    } else {
      props.resolve(true);
    }
  };

  return (
    <Backdrop
      data-default-colors
      tabIndex={-1}
      ref={(el) => {
        if (el && typeof props.text === "string") {
          el.focus();
          el.querySelector("input")?.select();
        }
      }}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          e.preventDefault();
          handleCancel();
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          handleOk();
        } else if (e.key === "Escape") {
          e.preventDefault();
          handleCancel();
        }
      }}
    >
      <Dialog>
        {typeof props.text === "string" ? (
          <Flex y="30rem 20rem" x="stretch">
            {props.text.split(/\n+/).map((line, i) => (
              <Balancer
                key={i}
                className={css`
                  margin: 0 auto;
                  text-align: center;
                  white-space: pre-wrap;
                `}
              >
                {line}
              </Balancer>
            ))}
            {props.type === "prompt" && (
              <input
                data-default-colors
                ref={inputRef}
                className={css`
                  ${ResetUaStyles};
                  margin-top: 1rem;
                  padding: 10rem;
                  border: 1px solid rgba(0, 0, 0, 0.1);
                  border-radius: 0.5rem;
                `}
                defaultValue={props.defaultValue}
              />
            )}
          </Flex>
        ) : (
          <props.text />
        )}

        <Flex
          x="center 40rem"
          className={css`
            height: 40rem;
          `}
        >
          {/* Cancel button */}
          {(props.type === "confirm" || props.type === "prompt") && (
            <ModalWindowButton onClick={handleCancel}>
              {props.options.cancelText ?? "Cancel"}
            </ModalWindowButton>
          )}

          {/* OK button */}
          <ModalWindowButton
            variant={props.options.danger ? "danger-primary" : "primary"}
            onClick={handleOk}
          >
            {props.options.okText ?? "OK"}
          </ModalWindowButton>
        </Flex>
      </Dialog>
    </Backdrop>
  );
};
export default ModalWindow;
