import { deserializeDataV1 } from "./serializationV1";
import { deserializeDataV2 } from "./serializationV2";

export default function getDataFromUrl(url: {
  pathname: string;
  searchParams: URLSearchParams;
}) {
  {
    // TODO: The URL unfortunately can sometimes contain '/', but we should to a
    // substitution so that that doesn't happen going forward.
    let match = url.pathname.match(/^\/s\/v2_(.*)$/);
    if (match) {
      let data = match[1];
      return deserializeDataV2(data);
    }
  }

  {
    // TODO: The URL unfortunately can sometimes contain '/', but we should to a
    // substitution so that that doesn't happen going forward.
    let match = url.pathname.match(/^\/s\/v1_(.*)$/);
    if (match) {
      let data = match[1];
      return deserializeDataV1(data);
    }
  }

  let param = url.searchParams.get("data");
  if (param) {
    let match = param.match(/^v1_(.*)$/);
    if (match) {
      let data = match[1];
      return deserializeDataV1(data);
    }
  }

  return null;
}
