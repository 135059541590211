import deleteReceipt from "../data/receipts/deleteReceipt";
import { Receipt } from "../data/types/Receipt";
import { useDataStorage } from "../data/useDataStorage";
import { useModalWindow } from "../visual/ModalWindow/useModalWindow";

export default function useDeleteReceipt() {
  const modal = useModalWindow();
  const storage = useDataStorage();

  return async function deleteReceiptFlow(receipt: Receipt) {
    const result = await modal.confirm(
      [
        //
        "Delete this receipt?",
        receipt.name,
        `${receipt.itemIds.length} item${
          receipt.itemIds.length === 1 ? "" : "s"
        }`,
      ].join("\n\n"),
      {
        danger: true,
        okText: "Delete",
      },
    );

    if (result) {
      storage.setData(deleteReceipt, receipt.id);
    }
  };
}
